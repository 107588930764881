
function FilterTriangle() {
  return (
    <svg width="10" height="5" xmlns="http://www.w3.org/2000/svg">
      <polygon points="5,0 0,5 10,5" fill="var(--filter-ui-90)" />
      <polygon points="5,1.4 1.4,5 8.6,5" fill="var(--filter-ui-99)" />
    </svg>
  )
}

export default FilterTriangle

