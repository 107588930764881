import "./FilterNavLink.scss";

function FilterNavLink({title, onClick, isActive}) {

  const classList = ["comp-filter-nav-link"];
  isActive && classList.push("active");
  onClick && classList.push("clickable");

  return (
    <div className={[classList.join(" ")]} onClick={onClick}>
      <div className="bar"></div>
      <div className="title">{title}</div>
    </div>
  )
}

export default FilterNavLink