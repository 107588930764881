import React from 'react'
import { useRouteError } from 'react-router-dom'

function ErrorPage() {
  const error = useRouteError();
  return (
    <div>
      <h2>{error.statusText}</h2>
      <p>{error.data}</p>
      {/* <p>{JSON.stringify(error)}</p> */}
    </div>
  )
}

export default ErrorPage