import { useEffect, useRef, useState } from "react";
import FilterButton from "./FilterButton";
import { ReactComponent as CloseIcon } from "./icon-close-6.svg"
import { ReactComponent as SearchIcon } from "./icon-search-12.svg"
import { ReactComponent as EyeOpenIcon } from "./icon-eye-open-12.svg"
import { ReactComponent as EyeClosedIcon } from "./icon-eye-closed-12.svg"
import "./FilterInput.scss"

function FilterInput({ icon, type = "search", value, onChange = () => { }, placeholder }) {
  const [inputType, setInputType] = useState(type);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const classList = ["comp-filter-input"]
  const inputRef = useRef();

  useEffect(() => {
    if (type === "password") {
      setInputType(isPasswordVisible ? "text" : "password");
    }
  }, [type, isPasswordVisible])

  return (
    <div className={classList.join(" ")}>
      {
        icon &&
        <div className="icon">{icon}</div>
      }
      <input
        ref={inputRef}
        type={inputType}
        className="input"
        placeholder={placeholder} value={value}
        onChange={(e) => onChange(e.target.value || "")}
      />
      <div className="buttons">
        {
          !value && type === "search" &&
          <FilterButton icon={<SearchIcon />} onClick={() => { inputRef.current.focus() }} />
        }
        {
          type === "password" &&
          <FilterButton icon={isPasswordVisible ? <EyeOpenIcon /> : <EyeClosedIcon />} onClick={() => { setIsPasswordVisible(v => !v) }} />
        }
        {
          value &&
          <FilterButton icon={<CloseIcon />} onClick={() => { onChange("") }} />
        }
      </div>
    </div>
  )
}

export default FilterInput