import "./FilterRadioToggle.scss"

function FilterRadioToggle({ isActive, onToggle }) {
  return (
    <div
      className={isActive ? 'comp-filter-radio-toggle active' : 'comp-filter-radio-toggle'}
      onClick={onToggle}
    >
      <div className="container">
        <div className="circle"></div>
      </div>
    </div>
  )
}

export default FilterRadioToggle