import "./FilterList.scss"
import { ReactComponent as CheckIcon } from "./icon-list-check-15.svg"

function FilterList({ items }) {
  return (
    <div className="comp-filter-list">
      {items?.map((item, i) => (
        <div className="item" key={i}>
          <div className="icon">
            <CheckIcon />
          </div>
          <div className="title">{item}</div>
        </div>
      ))}
    </div>
  )
}

export default FilterList