import "./FilterGroup.scss"

function FilterGroup({ children, isVertical }) {

  const classList = ["comp-filter-group"];
  isVertical && classList.push("vertical");

  return (
    <div className={classList.join(" ")}>
      {children}
    </div>
  )
}

export default FilterGroup