import "./FilterLabel.scss"

function FilterLabel({ title }) {
  return (
    <div className='comp-filter-label'>
      <div className="title">{title}</div>
      <div className="line"></div>
    </div>
  )
}

export default FilterLabel