import { useCallback, useEffect, useState } from "react"
import FilterAction from "../components/Filter/FilterAction"
import { DashboardTopBar } from "../templates/Dashboard"
import config from "../config";
import { useParams } from "react-router-dom";
import FilterTable from "../components/Filter/FilterTable";

function OrderDataSection() {
  const orderId = useParams().orderId;

  const [data, setData] = useState([]);

  const fetchOrderData = useCallback(async () => {
    const params = new URLSearchParams();
    params.set("orderId", orderId);

    const target = config.API_BASE + "/leads/data/?" + params.toString();
    let fethcedOrderData;
    try {
      fethcedOrderData = (await fetch(target)).json();
    } catch (error) {
      console.log(error)
    }

    if ((await fethcedOrderData).error) {
      return console.log((await fethcedOrderData).error);
    }
    setData(await fethcedOrderData);
  }, [orderId])

  useEffect(() => {
    fetchOrderData()
  }, [fetchOrderData])


  return (
    <div style={{ display: "flex", flexDirection: "column", width: "calc(100% - 260px)", height: "100%" }}>
      <DashboardTopBar>
        <h2 style={{ marginRight: "auto" }}>Order Data</h2>
        <FilterAction title="new order" onClick={() => { window.location.pathname = "/dashboard/orders/new" }} />
      </DashboardTopBar>
      <div style={{ padding: "20px", width: "100%", overflow: "auto"}}>
        <FilterTable data={data}/>
      </div>
    </div>
  )
}

export default OrderDataSection