import { useState } from "react";
import FilterSearchBar from "./FilterSearchBar";
import FilterRadioOption from "./FilterRadioOption";

function FilterRadioSelect({ options, focusOptionValue, onFocus, onSelect, isParent }) {

  const [searchValue, setSearchValue] = useState("");
  const collapseLength = 5;

  return (
    <div className="comp-filter-radio-select" style={{ display: "flex", flexDirection: "column", gap: "10px", height: "100%" }}>
      <div className="options">
        {options.filter(o => o.title.toLowerCase().includes(searchValue.toLowerCase())).map((o) => (
          <FilterRadioOption
            key={o.value}
            title={o.title}
            isParent={isParent}
            isActive={o.value === focusOptionValue}
            isFocus={focusOptionValue === o.value}
            onFocus={onFocus ? (() => onFocus(o.value)) : null}
            onToggle={() => onSelect(o.value)}
          />
        ))}
      </div>
      {
        options.length > collapseLength &&
        <FilterSearchBar
          value={searchValue}
          onChange={(value) => { setSearchValue(value) }}
        />
      }
    </div>
  )
}

export default FilterRadioSelect