import FilterTriangle from './FilterTriangle'
import "./FilterTag.scss"

function FilterTag({title}) {
  return (
    <div className="comp-filter-tag">
      <div className="title">{title}</div>
      <div className="arrow">
        <FilterTriangle />
      </div>
    </div>
  )
}

export default FilterTag