import { useNavigate, useParams } from "react-router-dom"
import { DashboardTopBar } from "../templates/Dashboard"
import { useEffect } from "react";

function PaymentConfirmSection() {

  const orderId = useParams().orderId;

  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/dashboard/orders");
    }, 3000)
  }, [navigate])

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%", overflow: "hidden" }}>
      <DashboardTopBar>
        <h2>Confirm order {orderId}.</h2>
      </DashboardTopBar>
      <p>Redirecting to /orders ...</p>
    </div>
  )
}

export default PaymentConfirmSection