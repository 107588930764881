const config = {
  // API_BASE: "http://localhost",
  API_BASE: window.location.protocol + '//' + window.location.hostname,
  // API_BASE: "",
  STRIPE_PK_TEST: "pk_test_51PL47eP6PigdnD7b5ruT1FLLFBxOwB50D9Pzk8ny5ws8rBneKwEiAiwMubNwhW7oB3y0eaSQRkNXrT3ivWJ5cCmJ006ubJNMeb",
  navLinks: [
    {
      title: "My Orders",
      path: "/dashboard/orders",
      isExact: true
    },
    {
      title: "All Orders",
      path: "/dashboard/orders/all",
      isAdminRoute: true
    },
  ]
}

export default config;