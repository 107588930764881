import "./SpecialButton.scss";

function SpecialButton({ label, color = "dodgerblue" }) {
  const classList = ["comp-filter-special-button"];
  return (
    <div className={classList.join(" ")} style={{"--comp-filter-special-button-color": color}}>
      <div className="effect-container">
        <div className="effect"></div>
      </div>
      <div className="content">
        <button className="label">{label}</button>
      </div>
    </div>
  )
}

export default SpecialButton