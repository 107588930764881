import "./FilterButton.scss"

function FilterButton({ icon, onClick, disabled, noRadiusRight, noRadiusLeft }) {

  const classList = ["comp-filter-button"];
  noRadiusLeft && classList.push("no-radius-left");
  noRadiusRight && classList.push("no-radius-right");

  return (
    <button
      className={classList.join(" ")}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      <div className="icon">{icon}</div>
    </button>
  )
}

export default FilterButton