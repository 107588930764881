import "./FilterAction.scss"

function FilterAction({title, onClick, variation = "pink"}) {
  const classList = ["comp-filter-action"]
  onClick && classList.push("clickable");
  classList.push(`variation-${variation}`);

  return (
    <button type="button" className={classList.join(" ")} onClick={onClick}>{title}</button>
  )
}

export default FilterAction