import FilterSelectOption from "./FilterSelectOption";
import "./FilterMultiSelect.scss"
import { useEffect, useRef, useState } from "react";
import FilterSearchBar from "./FilterSearchBar";
import FilterCollapsable from "./FilterCollapsable";
import FilterAction from "./FilterAction";

function FilterMultiSelect({ options, onSelect, onFocus, focusOptionValue, parentfocusOptionValue, sorter = (a, b) => 1 }) {
  const [searchValue, setSearchValue] = useState("");
  const step = 20;
  const [limit, setlimit] = useState(step);
  const collapseLength = 5;
  const allOptions = options?.sort(sorter).filter((o) => o.title.toLowerCase().includes(searchValue.toLowerCase()));

  // on parentfocusOptionValue change
  // update focusOptionValue to the first selected option
  // and reset search
  const prevParentfocusOptionValueRef = useRef();
  useEffect(() => {
    if (prevParentfocusOptionValueRef.current !== parentfocusOptionValue) {
      onFocus && onFocus(options?.filter(o => o.isSelected)[0]?.value)
      setSearchValue("");
      prevParentfocusOptionValueRef.current = parentfocusOptionValue;
    }
  }, [parentfocusOptionValue, onFocus, options])

  return (
    <div className="comp-filter-multi-select">
      <FilterCollapsable label={options?.length > collapseLength ? "selected" : ""} isCollapsable={(options?.length > collapseLength)}>
        {options?.sort(sorter)
          .filter(o => ((options?.length > collapseLength) ? o.isSelected : true))
          .map((o) => (
            <FilterSelectOption
              key={o.value}
              title={o.title || "‖ other ‖"}
              isSelected={o.isSelected}
              isFocusOption={focusOptionValue === o.value}
              onFocus={(o.isSelected && onFocus) ? (() => onFocus(o.value)) : null}
              onSelect={() => onSelect(o.value)}
            />
          ))}
      </FilterCollapsable>
      {
        (options?.length > collapseLength) &&
        <FilterCollapsable label={"all"} isCollapsable>
          {
            allOptions
              .slice(0, limit)
              .map((o) => (
                <FilterSelectOption
                  key={o.value}
                  title={o.title || "‖ other ‖"}
                  isSelected={o.isSelected}
                  isFocusOption={focusOptionValue === o.value}
                  onSelect={() => onSelect(o.value)}
                />
              ))
          }
          {
            limit + step < allOptions.length &&
            <div style={{
              marginTop: "10px",
              paddingLeft: "10px"
            }}>
              <FilterAction variation="blue" title={"load more"} onClick={() => { setlimit(l => l + step) }} />
            </div>
          }
        </FilterCollapsable>
      }
      {
        (options?.length > collapseLength) &&
        <FilterSearchBar
          value={searchValue}
          onChange={(value) => { setSearchValue(value) }}
        />
      }
    </div>
  )
}

export default FilterMultiSelect