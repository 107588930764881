import React from 'react'
import FilterButton from './FilterButton'
import './FilterFolder.scss'
import { ReactComponent as CloseIcon } from './icon-close-6.svg'
import { ReactComponent as ResetIcon } from './icon-reset-12.svg'

function FilterFolder({ options, onToggle, onClear, onClose, selectedOptionValue, noBorderRadiusTopRight, children }) {

  const classList = ["comp-filter-folder"]
  noBorderRadiusTopRight && classList.push("no-border-radius-top-right");

  return (
    <div className={classList.join(" ")}>
      <div className="header">
        {options.map(({ title, value }) => (
          <div key={value} className={selectedOptionValue === value ? "tab selected" : "tab"}>
            <div className="label" onClick={() => { onToggle(value) }}>{title}</div>
            {
              onClose &&
              <FilterButton icon={<CloseIcon />} onClick={() => { onClose(value) }} />
            }
            {
              onClear &&
              <FilterButton icon={<ResetIcon />} onClick={() => { onClear(value) }} />
            }
          </div>
        ))}
      </div>
      <div className="body">
        {children}
      </div>
    </div>
  )
}

export default FilterFolder