import { useRef, useState } from "react";
import "./FilterRangePicker.scss"
import FilterTriangle from "./FilterTriangle";

const valueToPercent = (v, s, e) => {
  return Math.round((v - s) * 100 / (e - s));
}

document.addEventListener("dragover", (event) => {
  event.preventDefault();
});

function FilterRangePicker({ min, max, start, end, value, isExactValue, onChange }) {
  const minPercent = Math.round((min - start) * 100 / (end - start));
  const maxPercent = Math.round((max - start) * 100 / (end - start));
  const valuePercent = valueToPercent(value, start, end);

  const pickerRef = useRef();

  const [isDrag, setIsDrag] = useState(false);
  const [dragValue, setDragValue] = useState(value);

  const onTagDragStart = (e) => {
    setIsDrag(true);
    e.dataTransfer.setDragImage(document.createElement("img"), 0, 0);
    e.dataTransfer.dropEffect = "move";
    e.dataTransfer.effectAllowed = "move";
  }

  const onTagDrag = (e) => {
    if (!isDrag) {
      return;
    }
    const box = pickerRef.current.getBoundingClientRect();
    const movement = e.clientX - box.x;
    const newPercent = movement / box.width;
    const newValue = Math.round(newPercent * (isExactValue ? end : max));
    const newValueCorrect = Math.min(Math.max(newValue, (isExactValue ? start : 0)), (isExactValue ? end : max));
    setDragValue(newValueCorrect);
  }
  const onTagDragEnd = (e) => {
    setIsDrag(false);
    const box = pickerRef.current.getBoundingClientRect()
    const movement = e.clientX - box.x;
    const newPercent = movement / box.width;
    const newValue = Math.round(newPercent * (isExactValue ? end : max));
    const newValueCorrect = Math.min(Math.max(newValue, (isExactValue ? start : 0)), (isExactValue ? end : max));
    onChange(newValueCorrect);
  }

  return (
    <div className="comp-filter-range-picker">
      <div className="picker" ref={pickerRef}>
        {
          isExactValue &&
          <Tag
            isDrag={isDrag}
            value={value}
            percent={valuePercent}
            onDrag={onTagDrag}
            onDragStart={onTagDragStart}
            onDragEnd={onTagDragEnd}
          />
        }
        {
          isExactValue && isDrag &&
          <Tag
            // isDrag={isDrag}
            isGhost={true}
            value={dragValue}
            percent={valueToPercent(dragValue, start, end)}
            onDrag={() => { }}
            onDragStart={() => { }}
            onDragEnd={() => { }}
          />
        }
        {
          !isExactValue &&
          <>
            <div className="tag" style={{ "--percent": `${minPercent}%` }}>
              <div className="number">
                {min}
              </div>
              <div className="arrow">
                <FilterTriangle />
              </div>
            </div>
            <div className="tag" style={{ "--percent": `${maxPercent}%` }}>
              <div className="number">
                {max}
              </div>
              <div className="arrow">
                <FilterTriangle />
              </div>
            </div>
          </>
        }
      </div>
      <div className="slider">
        <div className="container">
          {
            isExactValue &&
            <>
              <div className="area" style={{ "--minPercent": `${0}%`, "--maxPercent": `${isDrag ? valueToPercent(dragValue, start, end) : valuePercent}%` }}></div>
              <div className="line" style={{ "--percent": `${isDrag ? valueToPercent(dragValue, start, end) : valuePercent}%` }}></div>
            </>
          }
          {
            !isExactValue &&
            <>
              <div className="area" style={{ "--minPercent": `${minPercent}%`, "--maxPercent": `${maxPercent}%` }}></div>
              <div className="line" style={{ "--percent": `${minPercent}%` }}></div>
              <div className="line" style={{ "--percent": `${maxPercent}%` }}></div>
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default FilterRangePicker

const Tag = ({ percent, value, onDragStart, onDrag, onDragEnd, isDrag, isGhost }) => {
  const classList = ["tag"];
  isDrag && classList.push("drag");
  isGhost && classList.push("ghost");

  return (
    <div
      className={classList.join(" ")}
      style={{ "--percent": `${percent}%` }}
      draggable
      onDragStart={onDragStart}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
    >
      <div className="number">
        {value}
      </div>
      <div className="arrow">
        <FilterTriangle />
      </div>
    </div>
  )
}