import "./FilterTable.scss";

function FilterTable({ data }) {
  return (
    <table className="comp-filter-table">
      <thead>
        <tr>
          {
            Object.entries(data[0]?.leadData || []).map(entry => (
              <th key={entry[0]}><div className="header">{entry[0]}</div></th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {
          data?.map((d) => (
            <tr key={d._id}>
              {
                Object.entries(d.leadData).map(entry => (
                  <td key={entry[0]}><div className="cell">{entry[1].length < 20 ? entry[1] : entry[1].slice(0, 20) + "..."}</div></td>
                ))
              }
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default FilterTable