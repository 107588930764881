import FilterAction from '../components/Filter/FilterAction'
import SpecialButton from '../components/Filter/SpecialButton'

function Test() {
  return (
    <div style={{
      backgroundColor: "transparent",
      width: "100vw",
      height: "100vh",
      display: 'flex',
      gap: "10px",
      alignItems: "center",
      justifyContent: 'center'
    }}>
      <SpecialButton label={"specialize"} color='#0080ff'/>
      <SpecialButton label={"specialize"} color='teal'/>
      <SpecialButton label={"specialize"} color='orangered'/>
      <SpecialButton label={"specialize"} color='#ff0080'/>
      <SpecialButton label={"specialize"} color='#eabf00'/>
      <FilterAction title="test"/>
    </div>
  )
}

export default Test