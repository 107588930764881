import FilterToggle from "./FilterToggle"
import FilterTriangle from "./FilterTriangle";
import "./FilterSelectOption.scss"

function FilterSelectOption({ title, isSelected, onSelect, onFocus, isFocusOption }) {

  const classList = ["comp-filter-select-option"];
  (isFocusOption && onFocus) && classList.push("active");
  (isSelected && onFocus) && classList.push("selected");

  return (
    <div className={classList.join(" ")}>
      <div className="container">
        <FilterToggle isActive={isSelected} onToggle={onSelect} />
        <div className="title" onClick={onFocus} style={{ cursor: onFocus ? "pointer" : "default" }}>{title}</div>
      </div>
      <div className="arrow">
        <FilterTriangle />
      </div>
    </div>
  )
}

export default FilterSelectOption