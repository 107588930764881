import FilterButton from "./FilterButton"
import FilterToggle from "./FilterToggle"
import "./FilterCard.scss"
import { ReactComponent as ResetIcon } from "./icon-reset-12.svg";

function FilterCard({ title, unit, isActive, onClear, onRemove, onToggle, children, cardStyle, isFullWidth, isFullHeight }) {

  const classList = ["comp-filter-card"];
  cardStyle && classList.push(`style-${cardStyle}`);
  isFullWidth && classList.push("full-width");
  isFullHeight && classList.push("full-height");

  return (
    <div className={classList.join(" ")} style={{ "--scrollbar-filter-bg": "var(--filter-ui-97)" }}>
      <div className="header">
        <div className="left">
          {
            onToggle &&
            <FilterToggle isActive={isActive} onToggle={onToggle} />
          }
          <div className='title'>
            {title}
            &nbsp;
            {
              unit && <span className="unit">({unit})</span>
            }
          </div>
        </div>
        <div className="right">
          {
            onClear &&
            <FilterButton icon={<ResetIcon />} onClick={onClear} />
          }
          {
            onRemove &&
            <FilterButton icon={"x"} onClick={onRemove} />
          }
        </div>
      </div>
      <div className="body">
        {children}
      </div>
    </div>
  )
}

export default FilterCard