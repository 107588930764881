import { useCallback, useEffect, useMemo, useState } from "react";
import config from "../config";
import FilterFolder from "../components/Filter/FilterFolder";
import FilterGroup from "../components/Filter/FilterGroup";
import FilterParser, { getClearedFilter } from "../components/Filter/FilterParser";
import FilterCollapsable from "../components/Filter/FilterCollapsable";
import FilterCard from "../components/Filter/FilterCard";
import FilterList from "../components/Filter/FilterList";
import FilterRange from "../components/Filter/FilterRange";
import FilterTag from "../components/Filter/FilterTag";
import FilterAction from "../components/Filter/FilterAction";
import { DashboardTopBar } from "../templates/Dashboard";
import FilterLoadingCard from "../components/Filter/FilterLoadingCard";
import FilterFormMessage from "../components/Filter/FilterFormMessage";

function NewOrderSection() {

  const [selectedTab, setSelectedTab] = useState("location");
  const [selectedRecapTab, setSelectedRecapTab] = useState("order");
  const [maxVolume, setMaxVolume] = useState(0);
  const [volume, setVolume] = useState(0);
  const [PRICE_CPM, setCostPerMile] = useState(0);
  // const [optionalLabels, setOptionalLabels] = useState([
  //   { title: "Email", value: "email" },
  //   { title: "Phone", value: "phone" },
  // ]);

  const [filters, setFilters] = useState(null);
  const [filter, setFilter] = useState(null);
  const [prevFilter, setPrevFilter] = useState(null);
  const [leadSelectCriteria, setLeadSelectCriteria] = useState(null);
  const [locationCriteria, setLocationCriteria] = useState(null);
  const [criteriaToTitleMap, setCriteriaToTitleMap] = useState(null);
  const leadTypeFiltersValue = [
    {
      name: "lead-type",
      title: "Lead Type",
      valueType: "string",
      filterType: "radio-select",
      filterStatus: 0,
      isParentFilter: true,
      filterData: {
        focusOptionValue: "business",
        options: [
          { title: "business", value: "business" },
          { title: "customer", value: "customer" },
        ]
      }
    },
    {
      name: "country",
      title: "Country",
      valueType: "string",
      filterType: "radio-select",
      filterStatus: 0,
      isParentFilter: true,
      filterData: {
        focusOptionValue: "france",
        options: [
          { title: "France", value: "france" },
          { title: "Belgium", value: "belgium" },
        ]
      }
    }
  ];
  const [leadTypeFilters, setLeadTypeFilters] = useState(leadTypeFiltersValue);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [isVolumeUpdating, setIsVolumeUpdating] = useState(false);

  useEffect(() => {
    if (JSON.stringify(prevFilter) !== JSON.stringify(filter)) {
      setPrevFilter(filter);
    }
  }, [filter, prevFilter])

  const recapLeadType = useMemo(() => (
    {
      country: leadTypeFilters.find(f => f.name === "country").filterData.focusOptionValue,
      leadType: leadTypeFilters.find(f => f.name === "lead-type").filterData.focusOptionValue,
    }
  ), [leadTypeFilters])

  const getReducedData = (country, leadType) => {
    if (!(country && leadType)) {
      return;
    }
    setFiltersLoading(true);
    const params = new URLSearchParams();
    params.set("country", country);
    params.set("leadType", leadType);
    fetch(config.API_BASE + "/leads/reduce/?" + params.toString())
      .then(res => res.json())
      .then(res => {
        const newFilters = [];
        const uniqueCriteria = res.filters;
        const resLocationCriteria = res.locationCriteria;
        setLocationCriteria(res.locationCriteria);
        setLeadSelectCriteria(res.leadSelectCriteria);
        setCriteriaToTitleMap(res.criteriaToTitleMap);
        Object.entries(uniqueCriteria).forEach(criteria => {
          const parentFilterName = resLocationCriteria.indexOf(criteria[0]) > 0 ? resLocationCriteria[resLocationCriteria.indexOf(criteria[0]) - 1] : null;
          newFilters.push({
            name: criteria[0],
            title: res.criteriaToTitleMap[criteria[0]] || criteria[0],
            valueType: "string",
            filterType: "multi-select",
            filterStatus: 0,
            filterData: {
              focusOptionValue: criteria[0] === "key_country" ? JSON.stringify(criteria[1][0]) : null,
              parentFilterName: parentFilterName,
              isParentFilter: resLocationCriteria.slice(0, resLocationCriteria.length - 1).includes(criteria[0]),
              options: criteria[1].map(value => {
                let parentValue = null
                if ((value instanceof Object)) {
                  parentValue = value;
                  parentValue = Object.entries(parentValue).filter(entry => entry[0] !== criteria[0]);
                  parentValue = JSON.stringify(Object.fromEntries(parentValue));
                }
                return {
                  title: (value instanceof Object) ? value[criteria[0]] : value,
                  value: (value instanceof Object) ? JSON.stringify(value) : value,
                  parentValue: parentValue,
                  isSelected: criteria[0] === "key_country"
                }
              })
            }
          });
        });
        // newFilters.push({
        //   name: "country",
        //   title: "Country",
        //   valueType: "string",
        //   filterType: "multi-select",
        //   filterStatus: 0,
        //   filterData: {
        //     isParentFilter: true,
        //     options: res[0].uniqueCountries.map(c => ({ title: c, value: c, isSelected: false }))
        //   }
        // });
        // newFilters.push({
        //   name: "region",
        //   title: "Region",
        //   valueType: "string",
        //   filterType: "multi-select",
        //   filterStatus: 0,
        //   filterData: {
        //     isParentFilter: true,
        //     parentFilterName: "country",
        //     options: res[0].uniqueRegions.map(r => ({ title: r.region, value: JSON.stringify({ country: r.country, region: r.region }), parentValue: r.country, isSelected: false }))
        //   }
        // });
        // newFilters.push({
        //   name: "city",
        //   title: "City",
        //   valueType: "string",
        //   filterType: "multi-select",
        //   filterStatus: 0,
        //   filterData: {
        //     isParentFilter: true,
        //     parentFilterName: "region",
        //     options: res[0].uniqueCities.map(c => ({
        //       title: c.city,
        //       value: JSON.stringify({ country: c.country, region: c.region, city: c.city }),
        //       parentValue: JSON.stringify({ country: c.country, region: c.region }),
        //       isSelected: false
        //     }))
        //   }
        // });
        // newFilters.push({
        //   name: "zip-code",
        //   title: "Codes",
        //   valueType: "string",
        //   filterType: "multi-select",
        //   filterStatus: 0,
        //   filterData: {
        //     parentFilterName: "city",
        //     options: res[0].uniquePostalCodes.map(c => ({
        //       title: c.postalCode,
        //       value: JSON.stringify({ country: c.country, region: c.region, city: c.city, code: c.postalCode }),
        //       parentValue: JSON.stringify({ country: c.country, region: c.region, city: c.city }),
        //       isSelected: false
        //     }))
        //   }
        // });
        // newFilters.push({
        //   name: "occupation",
        //   title: "Occupation",
        //   valueType: "string",
        //   filterType: "multi-select",
        //   filterStatus: 0,
        //   filterData: {
        //     options: res[0].customerOccupation.map(o => ({ title: o, value: o, isSelected: false }))
        //   }
        // });
        // newFilters.push({
        //   name: "education",
        //   title: "Education",
        //   valueType: "string",
        //   filterType: "multi-select",
        //   filterStatus: 0,
        //   filterData: {
        //     options: res[0].customerEducation.map(o => ({ title: o, value: o, isSelected: false }))
        //   }
        // });
        // newFilters.push({
        //   name: "marital-status",
        //   title: "Marital Status",
        //   valueType: "string",
        //   filterType: "multi-select",
        //   filterStatus: 0,
        //   filterData: {
        //     options: res[0].customerMaritalStatus.map(o => ({ title: o, value: o, isSelected: false }))
        //   }
        // });
        // newFilters.push({
        //   name: "gender",
        //   title: "Gender",
        //   valueType: "string",
        //   filterType: "multi-select",
        //   filterStatus: 0,
        //   filterData: {
        //     options: res[0].customerGender.map(o => ({ title: o, value: o, isSelected: false }))
        //   }
        // });
        // newFilters.push({
        //   name: "activity",
        //   title: "Activity",
        //   valueType: "string",
        //   filterType: "multi-select",
        //   filterStatus: 0,
        //   filterData: {
        //     options: res[0].businessActivity.map(o => ({ title: o, value: o, isSelected: false }))
        //   }
        // });
        // newFilters.push({
        //   name: "legal-form",
        //   title: "Legal Form",
        //   valueType: "string",
        //   filterType: "multi-select",
        //   filterStatus: 0,
        //   filterData: {
        //     options: res[0].businessType.map(o => ({ title: o, value: o, isSelected: false }))
        //   }
        // });
        // newFilters.push({
        //   name: "age",
        //   title: "Age",
        //   valueType: "number",
        //   valueUnit: "years",
        //   filterType: "range",
        //   filterStatus: 0,
        //   filterData: {
        //     start: res[0].customerAgeMin,
        //     end: res[0].customerAgeMax,
        //     min: res[0].customerAgeMin,
        //     max: res[0].customerAgeMax,
        //   }
        // });
        // newFilters.push({
        //   name: "income",
        //   title: "Income",
        //   valueType: "number",
        //   valueUnit: "",
        //   filterType: "range",
        //   filterStatus: 0,
        //   filterData: {
        //     start: res[0].customerIncomeMin,
        //     end: res[0].customerIncomeMax,
        //     min: res[0].customerIncomeMin,
        //     max: res[0].customerIncomeMax,
        //   }
        // });
        // newFilters.push({
        //   name: "turnover",
        //   title: "Turnover",
        //   valueType: "number",
        //   valueUnit: "dollars",
        //   filterType: "range",
        //   filterStatus: 0,
        //   filterData: {
        //     start: res[0].businessTurnoverMin,
        //     end: res[0].businessTurnoverMax,
        //     min: res[0].businessTurnoverMin,
        //     max: res[0].businessTurnoverMax,
        //   }
        // });
        // newFilters.push({
        //   name: "seniority",
        //   title: "Seniority",
        //   valueType: "number",
        //   valueUnit: "dollars",
        //   filterType: "range",
        //   filterStatus: 0,
        //   filterData: {
        //     start: res[0].businessSeniorityMin,
        //     end: res[0].businessSeniorityMax,
        //     min: res[0].businessSeniorityMin,
        //     max: res[0].businessSeniorityMax,
        //   }
        // });
        // newFilters.push({
        //   name: "employees",
        //   title: "Employees",
        //   valueType: "number",
        //   valueUnit: "dollars",
        //   filterType: "range",
        //   filterStatus: 0,
        //   filterData: {
        //     start: res[0].businessEmployeesMin,
        //     end: res[0].businessEmployeesMax,
        //     min: res[0].businessEmployeesMin,
        //     max: res[0].businessEmployeesMax,
        //   }
        // });
        setFilters(newFilters);
        setFiltersLoading(false);
      })
  }

  // fetch reduced data
  useEffect(() => {
    getReducedData(recapLeadType.country, recapLeadType.leadType);
  }, [recapLeadType])

  // calc recap
  const recapData = useMemo(() => ({
    location: Object.fromEntries(
      locationCriteria?.map(l => {
        return [l, filters?.find((f) => (f.name === l))?.filterData.options.filter(o => o.isSelected)]
      })
      || []
    ),
    lead: Object.fromEntries(
      leadSelectCriteria?.map(l => {
        return [l, filters?.find((f) => (f.name === l))?.filterData.options.filter(o => o.isSelected)]
      })
      || []
    ),
  }), [filters, leadSelectCriteria, locationCriteria])

  const recapTabs = [
    {
      title: "Recap",
      value: "recap",
    },
    {
      title: "Order",
      value: "order",
    }
  ]
  const criteriaTabs = [
    {
      title: "Location",
      value: "location",
      filters: locationCriteria
    },
    {
      title: "Lead",
      value: "lead",
      filters: leadSelectCriteria
    }
  ]

  // update filter

  const updateFilter = useCallback(() => {
    if (!(Object.entries(recapData.location || {}).length || Object.entries(recapData.data || {}).length)) {
      return;
    }
    const newFilter = {
      location: Object.fromEntries(
        Object.entries(recapData.location)
          .map(entry => ([entry[0], entry[1].map(v => v.value)]))
          .filter(entry => (entry[1].length))
      ),
      data: Object.fromEntries(
        Object.entries(recapData.lead)
          .map(entry => ([entry[0], entry[1].map(v => v.value)]))
          .filter(entry => (entry[1].length))
      )
    }
    setFilter(newFilter);
  }, [recapData])

  useEffect(() => {
    updateFilter();
  }, [updateFilter])

  // update volume

  const updateMaxVolume = (fil, rec) => {
    if (!(fil)) {
      return;
    }
    setIsVolumeUpdating(true);
    const params = new URLSearchParams();
    params.set("filter", JSON.stringify({ ...fil, country: rec.country, leadType: rec.leadType }));

    fetch(config.API_BASE + "/leads/volume/?" + params.toString())
      .then(res => res.json())
      .then(res => {
        setMaxVolume(res.volume);
        setVolume((v) => Math.min(v, res.volume));
        setCostPerMile(res.costPerMile);
        setIsVolumeUpdating(false);
      })
  }

  useEffect(() => {
    updateMaxVolume(prevFilter, recapLeadType);
  }, [prevFilter, recapLeadType])

  // save order

  const [orderError, setOrderError] = useState("");

  const saveOrderHandler = ({ isPay }) => {
    fetch(config.API_BASE + "/orders/new/", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        volume: volume,
        filter: filter,
        country: recapLeadType.country,
        leadType: recapLeadType.leadType
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setOrderError(res.error)
          setTimeout(() => {
            setOrderError("")
          }, 3000);
          return console.log(res.error);
        }
        if (!(res.orderId)) {
          return;
        }
        if (isPay) {
          window.location.pathname = `/dashboard/orders/${res.orderId}/pay`
        } else {
          window.location.pathname = `/dashboard/orders/`
        }
      })
  }

  const tabClearHandler = (tabValue) => {
    const tabFilters = criteriaTabs.find(t => t.value === tabValue).filters;
    setFilters((prevFilters) => {
      let newFilters = [...prevFilters];
      newFilters = newFilters.map(f => {
        if (!(tabFilters.includes(f.name))) {
          return f;
        }
        return getClearedFilter(f);
      })
      return newFilters;
    })
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "calc(100% - 260px)", height: "100%" }}>
      <DashboardTopBar>
        <div style={{ display: 'flex', width: "100%" }}>
          <h2 style={{ marginRight: "auto" }}>New Order</h2>
          {
            !filtersLoading && !isVolumeUpdating &&
            <FilterAction title={"save order"} onClick={() => saveOrderHandler({ isPay: false })} />
          }
        </div>
      </DashboardTopBar>
      <div style={{
        display: "flex",
        gap: "20px",
        width: "100%",
        height: "100%",
        overflow: "auto",
        padding: "20px",
      }}>
        {filtersLoading && <FilterLoadingCard title="loading filters" />}
        {
          !filtersLoading &&
          <>
            <FilterFolder
              selectedOptionValue={selectedTab}
              options={criteriaTabs}
              onToggle={(value) => setSelectedTab(value)}
              onClear={tabClearHandler}
            >
              <div style={filtersLoading || isVolumeUpdating ? { opacity: "0.5", pointerEvents: "none" } : { opacity: "1" }}>
                {
                  selectedTab === "lead" &&
                  <FilterGroup>
                    <FilterParser filter={leadTypeFilters?.find(f => f.name === "lead-type")} filters={leadTypeFilters} setFilters={setLeadTypeFilters} />
                    {
                      leadSelectCriteria?.map(criteria => (
                        <FilterParser key={criteria} filter={filters?.find(f => f.name === criteria)} filters={filters} setFilters={setFilters} />
                      ))
                    }
                  </FilterGroup>
                }
                {
                  selectedTab === "location" &&
                  <FilterGroup>
                    <FilterParser filter={leadTypeFilters?.find(f => f.name === "country")} filters={leadTypeFilters} setFilters={setLeadTypeFilters} />
                    {
                      locationCriteria?.map(criteria => (
                        criteria !== "key_country" &&
                        <FilterParser key={criteria} filter={filters?.find(f => f.name === criteria)} filters={filters} setFilters={setFilters} />
                      ))
                    }
                  </FilterGroup>
                }
              </div>
            </FilterFolder>
            <div style={{ maxWidth: "280px" }}>
              <FilterFolder
                noBorderRadiusTopRight
                selectedOptionValue={selectedRecapTab}
                options={recapTabs}
                onToggle={(value) => setSelectedRecapTab(value)}
              >
                {
                  selectedRecapTab === "recap" &&
                  <FilterGroup isVertical>
                    <FilterCard isFullWidth title="location recap">
                      <div style={{ display: 'flex', flexDirection: "column", gap: "20px" }}>
                        {
                          locationCriteria.map(criteria => (
                            !!(recapData.location[criteria]?.length) &&
                            <FilterCollapsable key={criteria} isCollapsable label={`${criteriaToTitleMap[criteria] || criteria} (${recapData.location[criteria]?.length})`}>
                              <FilterList items={recapData.location[criteria]?.map(c => c.title)} />
                            </FilterCollapsable>
                          ))
                        }
                      </div>
                    </FilterCard>
                    <FilterCard isFullWidth title="lead recap">
                      <div style={{ display: 'flex', flexDirection: "column", gap: "20px" }}>
                        {
                          leadSelectCriteria.map(criteria => (
                            !!(recapData.lead[criteria]?.length) &&
                            <FilterCollapsable key={criteria} isCollapsable label={`${criteriaToTitleMap[criteria] || criteria} (${recapData.lead[criteria]?.length})`}>
                              <FilterList items={recapData.lead[criteria]?.map(c => c.title)} />
                            </FilterCollapsable>
                          ))
                        }
                      </div>
                    </FilterCard>
                  </FilterGroup>
                }
                {
                  selectedRecapTab === "order" &&
                  <FilterGroup isVertical>
                    <div style={isVolumeUpdating ? { opacity: "0.5", pointerEvents: "none" } : { opacity: "1" }}>
                      <FilterCard isFullWidth title="Purchase" cardStyle={"special"}>
                        <div style={{ display: 'flex', flexDirection: "column", gap: "20px" }}>
                          <FilterCollapsable noMaxHeight label={`Volume (max: ${maxVolume})`}>
                            <FilterRange
                              isExactValue
                              value={volume}
                              start={0}
                              end={maxVolume}
                              onChange={(value) => {
                                setVolume(value);
                              }}
                            />
                          </FilterCollapsable>
                          <FilterCollapsable label={`Price ($${PRICE_CPM} CPM)`}>
                            <div style={{ display: "flex", gap: "5px" }}>
                              <FilterTag title={`$${((volume / 1000) * PRICE_CPM).toFixed(2)}`} />
                              <FilterAction title={"pay"} onClick={() => { saveOrderHandler({ isPay: true }) }} />
                            </div>
                          </FilterCollapsable>
                          {
                            orderError &&
                            <FilterFormMessage type="error" message={orderError} />
                          }
                        </div>
                      </FilterCard>
                    </div>
                  </FilterGroup>
                }
              </FilterFolder>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default NewOrderSection