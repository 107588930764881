function FilterImageBox({ src, maxWidth, maxHeight }) {
  return (
    <div style={{
      display: "flex",
      maxHeight: maxHeight,
      maxWidth: maxWidth,
      width: "100%",
      overflow: "auto",
      marginTop: "10px",
      border: "1px solid var(--filter-ui-93)",
      borderRadius: "5px",
      backgroundColor: "var(--filter-ui-97)",
      "--scrollbar-filter-bg": "var(--filter-ui-97)"
    }}>
      <img src={src} alt="" style={{height: "fit-content"}} />
    </div>
  )
}

export default FilterImageBox