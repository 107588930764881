import { useState } from "react";
import "./FilterFieldAction.scss";
import { ReactComponent as IconCopy } from "./icon-copy.svg";

function FilterFieldAction({ name, value, action }) {
  const classList = ["comp-filter-field-action"];
  action && classList.push("is-clickable");
  const [feedbackShown, setFeedbackShown] = useState(false);

  const clickHandler = () => {
    if (!action?.onClick) {
      return;
    }
    action.onClick();
    setFeedbackShown(true);
    setTimeout(() => {
      setFeedbackShown(false);
    }, 3000)
  }

  return (
    <div className={classList.join(" ")} onClick={clickHandler}>
      <div className="head">
        <div className="name">{name}</div>
        {
          feedbackShown && action?.feedback &&
          <div className="feedback">{<IconCopy />}{action.feedback}</div>
        }
        {
          !feedbackShown && action?.title &&
          <div className="action">{<IconCopy />}{action.title}</div>
        }
      </div>
      <div className="value">{value}</div>
    </div>
  )
}

export default FilterFieldAction