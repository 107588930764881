import React, { useState } from 'react'
import FilterAction from '../components/Filter/FilterAction';
import FilterInput from '../components/Filter/FilterInput';
import { PiPassword, PiAt, PiWarehouse, PiFlag, PiPhone, PiUser, PiBuilding } from "react-icons/pi";
import FilterFormMessage from '../components/Filter/FilterFormMessage';
import config from '../config';

const FilterProgressBar = ({ base, progress }) => {
  return (
    <div style={{ backgroundColor: "var(--filter-ui-93)", height: "5px", width: "100%", borderRadius: "3px" }}>
      <div style={{
        transition: "width 300ms ease-out",
        backgroundColor: "var(--filter-ui-pink)",
        borderRadius: "3px",
        height: "100%",
        width: `${Math.round((progress / base) * 100)}%`
      }}></div>
    </div>
  )
}

const dataSchema = [
  {
    name: "firstName",
    type: "text",
    placeholder: "first name",
    icon: <PiUser />,
  },
  {
    name: "lastName",
    type: "text",
    placeholder: "last name",
    icon: <PiUser />,
  },
  {
    name: "phoneNumber",
    type: "phone",
    placeholder: "phone number",
    icon: <PiPhone />,
  },
  {
    name: "country",
    type: "text",
    placeholder: "country",
    icon: <PiFlag />,
  },
  {
    name: "sector",
    type: "text",
    placeholder: "sector",
    icon: <PiWarehouse />,
  },
  {
    name: "company",
    type: "text",
    placeholder: "company",
    icon: <PiBuilding />,
  },
  {
    name: "email",
    type: "email",
    placeholder: "email",
    icon: <PiAt />,
  },
  {
    name: "password",
    type: "password",
    placeholder: "password",
    icon: <PiPassword />,
  },
  {
    name: "passwordConfirm",
    type: "password",
    placeholder: "confirm password",
    icon: <PiPassword />,
  },
]

function RegisterPage() {

  const [registerData, setRegisterData] = useState({});
  const [registerStep, setRegisterStep] = useState(0);
  const [registerMessage, setRegisterMessage] = useState(null);
  const maxStep = 2;

  const registerNextStep = () => {
    setRegisterStep(s => Math.min(s + 1, maxStep));
  }

  const registerPreviousStep = () => {
    setRegisterStep(s => Math.max(s - 1, 0));
  }

  const updateRegisterData = (field, value) => {
    setRegisterData(data => ({ ...data, [field]: value }));
  }

  const FieldControler = ({ name, type, icon, placeholder }) => {
    return (
      <FilterInput icon={icon} type={type} value={registerData[name] || ""} placeholder={placeholder} onChange={(value) => { updateRegisterData(name, value) }} />
    )
  }

  const onRegister = () => {
    const data = Object.fromEntries(dataSchema.map(d => [d.name, registerData[d.name]]))
    const emptyData = []
    Object.entries(data).forEach(d => {
      if (!d[1]) {
        emptyData.push(d[0])
      }
    })
    if (emptyData.length) {
      showMessage({
        type: "error",
        message: `the following fields are required: ${dataSchema.filter(d => emptyData.includes(d.name)).map(d => d.placeholder).join(", ")}`
      })
      return;
    }

    if (data.password !== data.passwordConfirm) {
      showMessage({
        type: "error",
        message: "passwords don't match"
      }, 5000)
      return;
    }

    const { email, password, passwordConfirm, ...finalData } = data;

    fetch(config.API_BASE + "/auth/register", {
      method: "post",
      body: JSON.stringify({ email, password, data: finalData }),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          showMessage({
            type: "success",
            message: "registered successfully"
          }, 5000)
          setRegisterData({});
        } else {
          showMessage({
            type: "error",
            message: res.error
          }, 5000)
        }
      })
  }

  const showMessage = (message, duration) => {
    setRegisterMessage(message);
    if (duration) {
      setTimeout(() => {
        setRegisterMessage(null);
      }, duration)
    }
  }

  return (
    <form onSubmit={(e) => { e.preventDefault() }} style={{ display: "flex", flexDirection: "column", gap: "15px", padding: "10px" }}>
      <FilterProgressBar base={maxStep + 1} progress={registerStep + 1} />
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {
          registerStep === 0 &&
          <>
            {FieldControler(dataSchema.find(d => d.name === "firstName"))}
            {FieldControler(dataSchema.find(d => d.name === "lastName"))}
            {FieldControler(dataSchema.find(d => d.name === "phoneNumber"))}
          </>
        }
        {
          registerStep === 1 &&
          <>
            {FieldControler(dataSchema.find(d => d.name === "country"))}
            {FieldControler(dataSchema.find(d => d.name === "sector"))}
            {FieldControler(dataSchema.find(d => d.name === "company"))}
          </>
        }
        {
          registerStep === 2 &&
          <>
            {FieldControler(dataSchema.find(d => d.name === "email"))}
            {FieldControler(dataSchema.find(d => d.name === "password"))}
            {FieldControler(dataSchema.find(d => d.name === "passwordConfirm"))}
          </>
        }
      </div>
      <div style={{ display: "flex", gap: "5px", width: "100%", justifyContent: "space-between" }}>
        {
          registerStep === 0 &&
          <>
            <div></div>
            <FilterAction title={"next"} onClick={registerNextStep} />
          </>
        }
        {
          registerStep === 1 &&
          <>
            <FilterAction title={"back"} onClick={registerPreviousStep} variation='blue' />
            <FilterAction title={"next"} onClick={registerNextStep} />
          </>
        }
        {
          registerStep === 2 &&
          <>
            <FilterAction title={"back"} onClick={registerPreviousStep} variation='blue' />
            <FilterAction title={"register"} onClick={onRegister} />
          </>
        }
      </div>
      {
        registerMessage &&
        <FilterFormMessage type={registerMessage.type} message={registerMessage.message} />
      }
    </form>
  )
}

export default RegisterPage