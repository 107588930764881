import FilterRadioToggle from './FilterRadioToggle'
import FilterTriangle from './FilterTriangle'
import './FilterRadioOption.scss'

function FilterRadioOption({ title, onToggle, isActive, isFocus, onFocus, isParent }) {
  const classList = ["comp-filter-radio-option"];
  isFocus && classList.push("focus");
  isActive && classList.push("active");
  isParent && classList.push("parent");

  return (
    <div className={classList.join(" ")}>
      <div className="container">
        <FilterRadioToggle isActive={isActive} onToggle={onToggle} />
        <div className="title" onClick={onFocus}>{title}</div>
      </div>
      {
        isParent &&
        <div className="arrow">
          <FilterTriangle />
        </div>
      }
    </div>
  )
}

export default FilterRadioOption