import { useState } from "react"
import FilterButton from "./FilterButton";
import "./FilterCollapsable.scss"
import FilterLabel from "./FilterLabel";
import { ReactComponent as ArrowUpIcon } from "./icon-arrow-up-6.svg";
import { ReactComponent as ArrowDownIcon } from "./icon-arrow-down-6.svg";

function FilterCollapsable({ isCollapsable, maxHeight = 150, label, children, isDefaultOpen = true, noMaxHeight }) {

  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const classList = ["comp-filter-collapsable"];
  (isCollapsable && !isOpen) && classList.push("collapse");
  (noMaxHeight) && classList.push("no-max-height");

  return (
    <div className={classList.join(" ")} style={{ "--collapsable-max-height": `${maxHeight}px` }}>
      {
        label &&
        <div className="header">
          {
            isCollapsable &&
            <FilterButton
              icon={isOpen ? <ArrowDownIcon /> : <ArrowUpIcon />}
              onClick={() => { setIsOpen(o => !o) }}
            />
          }
          <FilterLabel title={label} />
        </div>
      }
      {
        (!isCollapsable || isOpen) &&
        <div className="body">
          {children}
        </div>
      }
    </div>
  )
}

export default FilterCollapsable