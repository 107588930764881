import FilterAction from "./FilterAction"
import "./FilterOrderCard.scss"
import { useContext } from "react";
import { appGlobalContext } from "../../App";

function FilterOrderCard({ order, onDownload, onViewAttachment, onMarkAsPaid }) {

  const { appGlobalData } = useContext(appGlobalContext);
  const user = appGlobalData.user;

  const classList = ["comp-filter-order-card"];
  order.isLoading && classList.push("loading");

  const date = new Date(order.metadata.orderedAt);
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const formattedDate = `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()} — ${date.toLocaleTimeString()}`

  return (
    <div className={classList.join(" ")}>
      <div className="head">
        <div className="title">{order.orderId}</div>
        <div className="tags">
          {
            order.payment.status === "initial" &&
            <div className={"tag"}>{"unpaid"}</div>
          }
          {
            order.payment.status === "pending" &&
            <div className={"tag"}>{"payment..."}</div>
          }
          {
            order.payment.status === "done" &&
            <div className={"tag special"}>{"paid"}</div>
          }
          {
            order.payment.status === "done" && order.export.status !== "done" &&
            <div className={"tag"}>{"export..."}</div>
          }
          {
            order.export.status === "done" &&
            <div className={"tag special"}>{"exported"}</div>
          }
        </div>
      </div>
      <div className="body">
        <div className="details">
          {
            order.username &&
            <div className="detail">
              <span className="name">username</span>
              <span className="value">{order.username}</span>
            </div>
          }
          <div className="detail">
            <span className="name">ordered at</span>
            <span className="value">{formattedDate}</span>
          </div>
          <div className="detail">
            <span className="name">lead type</span>
            <span className="value">{order.filter.leadType}</span>
          </div>
          <div className="detail">
            <span className="name">country</span>
            <span className="value">{order.filter.country}</span>
          </div>
          <div className="detail">
            <span className="name">volume</span>
            <span className="value">{order.filter.volume}</span>
          </div>
        </div>
        <div className="actions">
          {
            order.payment.status === "initial" && user.userId === order.metadata.userId &&
            <FilterAction title="pay" onClick={() => { window.location.pathname = "/dashboard/orders/" + order.orderId + "/pay" }} />
          }
          {
            user.isAdmin && order.payment.method === "offline" &&
            <FilterAction title="attachment" onClick={() => onViewAttachment(order)} variation="blue" />
          }
          {
            user.isAdmin && order.payment.status !== "done" &&
            <FilterAction title="mark as paid" onClick={() => onMarkAsPaid(order)} />
          }
          {/* {
            user.isAdmin && order.export.status === "initial" && order.payment.status === "done" &&
            <FilterAction title="export" variation="blue" onClick={() => { onExport(order.orderId) }} />
          } */}
          {
            order.export.status === "done" && order.payment.status === "done" &&
            <>
              <FilterAction title="download (.csv)" variation="blue" onClick={() => { onDownload(order, true) }} />
              <FilterAction title="download (.xlsx)" variation="blue" onClick={() => { onDownload(order) }} />
            </>
          }
        </div>

      </div>
    </div>
  )
}

export default FilterOrderCard