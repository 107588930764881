import FilterCard from "./FilterCard";
import FilterMultiSelect from "./FilterMultiSelect";
import FilterRadioSelect from "./FilterRadioSelect";
import FilterRange from "./FilterRange";

function FilterParser({ filter, filters, setFilters }) {

  // const filterToggleHandler = () => {
  //   setFilters((prevFilters) => {
  //     const newFilters = [...prevFilters];
  //     const foundFilter = newFilters.find(found => found.name === filter.name)
  //     foundFilter.filterStatus = foundFilter.filterStatus === 0 ? 1 : 0;
  //     return newFilters;
  //   })
  // }

  const filterClearHandler = () => {
    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      let foundFilter = newFilters.find(found => found.name === filter.name);
      foundFilter = getClearedFilter(foundFilter);
      return newFilters;
    })
  }

  const multiSelectSelectHandler = (value) => {
    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      const foundFilter = newFilters.find(found => found.name === filter.name);
      const foundOption = foundFilter.filterData.options.find(o => o.value === value);
      foundOption.isSelected = !(foundOption.isSelected);
      if (foundOption.isSelected) {
        foundFilter.filterData.focusOptionValue = value;
      } else {
        const selectedOptions = foundFilter.filterData.options.filter(o => o.isSelected && o.parentValue === foundOption.parentValue);
        foundFilter.filterData.focusOptionValue = selectedOptions.length ? selectedOptions[0].value : "";
      }
      return newFilters;
    })
  }

  const radioSelectSelectHandler = (value) => {
    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      const foundFilter = newFilters.find(found => found.name === filter.name);
      foundFilter.filterData.focusOptionValue = value;
      return newFilters;
    })
  }

  const multiSelectFocusHandler = (value) => {
    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      const foundFilter = newFilters.find(found => found.name === filter.name);
      foundFilter.filterData.focusOptionValue = value;
      return newFilters;
    })
  }

  const rangeChangeHandler = (value, valueType) => {
    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      const foundFilter = newFilters.find(found => found.name === filter.name);
      foundFilter.filterData[valueType] = parseInt(value);
      return newFilters;
    })
  }

  return (
    <FilterCard
      title={filter?.title}
      unit={filter?.valueUnit}
      isActive={filter?.filterStatus === 1}
      // onToggle={filterToggleHandler}
      onClear={filterClearHandler}
    >
      {
        filter?.filterType === "range" &&
        <FilterRange
          onChange={rangeChangeHandler}
          min={filter?.filterData.min}
          max={filter?.filterData.max}
          start={filter?.filterData.start}
          end={filter?.filterData.end}
          isExactValue={filter?.filterData.isExactValue}
          value={filter?.filterData.value}
        />
      }
      {
        filter?.filterType === "multi-select" &&
        <FilterMultiSelect
          options={filter?.filterData.options.filter((o, i) => {
            const parentFilter = filters.find(fi => fi.name === filter?.filterData.parentFilterName);
            return (parentFilter ? parentFilter.filterData.focusOptionValue === o.parentValue : true);
          })}
          parentfocusOptionValue={filters.find(fi => fi.name === filter?.filterData.parentFilterName)?.filterData.focusOptionValue}
          onSelect={multiSelectSelectHandler}
          focusOptionValue={filter?.filterData.focusOptionValue}
          onFocus={filter?.filterData.isParentFilter ? multiSelectFocusHandler : null}
          sorter={(optionA, optionB) => optionA.value > optionB.value ? 1 : -1}
        />
      }
      {
        filter?.filterType === "radio-select" &&
        <FilterRadioSelect
          options={filter?.filterData.options}
          focusOptionValue={filter?.filterData.focusOptionValue}
          onFocus={multiSelectFocusHandler}
          isParent={filter?.isParentFilter}
          onSelect={radioSelectSelectHandler}
        />
      }
    </FilterCard>
  )
}

export default FilterParser

export const getClearedFilter = (f) => {
  let filter = { ...f };
  switch (filter?.filterType) {
    case "range":
      filter.filterData.min = filter?.filterData.start;
      filter.filterData.max = filter?.filterData.end;
      if (filter?.filterData.isExactValue) {
        filter.filterData.value = filter?.filterData.end;
      }
      break;
    case "multi-select":
      filter.filterData.options = filter?.filterData.options.map(o => ({ ...o, isSelected: false }));
      break;
    default:
      break;
  }
  return filter;
}