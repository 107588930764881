import { useRef } from "react";
import "./FilterInputUpload.scss";

function FilterInputUpload({ file, placeholder, selectAction, changeAction, onSelect }) {

  const classList = ["comp-filter-input-upload"];
  const inputRef = useRef();

  const actionClickHandler = () => {
    inputRef.current.click();
  }

  const inputChangeHandler = (e) => {
    onSelect(e.target.files[0]);
  }

  return (
    <div className={classList.join(" ")} onClick={actionClickHandler}>
      <input type='file' className="input" ref={inputRef} onChange={inputChangeHandler} />
      <div className="placeholder">{file?.name || placeholder}</div>
      <div className="action">{file ? changeAction : selectAction}</div>
    </div>
  )
}

export default FilterInputUpload