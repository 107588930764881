import "./FilterFormMessage.scss"
import { ReactComponent as XIcon } from "./icon-x.svg"
import { ReactComponent as CheckIcon } from "./icon-list-check-15.svg"

function FilterFormMessage({ message, type = "success" }) {

  let icon;
  switch (type) {
    case "error":
      icon = <XIcon />
      break;
    case "success":
      icon = <CheckIcon />
      break;

    default:
      icon = <CheckIcon />
      break;
  }

  return (
    <div className='comp-filter-form-message'>
      <div className="icon">{icon}</div>
      {message}
    </div>
  )
}

export default FilterFormMessage